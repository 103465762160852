/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

const { default : config } = require('./src/data/config');

exports.onRouteUpdate = ({ location, prevLocation }) => {
  if (window.gtag) {
    window.gtag('config', config.GA_MEASUREMENT_ID, {'page_path': location.pathname});
  }
};
